<!-- Start Main Banner Area -->
<div class="main-banner">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7 col-md-12 p-0">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <h1>Custom software with expertise</h1>
                            <p>
                                BRAHMAN develops custom software for businesses
                                to maximize their efficiency. Through deep
                                industry insights, we provide custom software
                                development with IT consulting.
                            </p>
                            <div class="btn-box">
                                <a
                                    class="btn btn-primary"
                                    [ngxScrollTo]="readMore"
                                >
                                    Read more
                                </a>
                                <a routerLink="/contact" class="btn btn-light">
                                    Contact
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12 p-0">
                <div class="main-banner-image">
                    <img src="assets/img/banner-img1.png" alt="image" />
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape12.png" alt="image"></div> -->
</div>
<!-- End Main Banner Area -->

<!-- Start Services Area -->
<section class="services-area ptb-110" #readMore>
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Our Services</span>
                <h2>Custom software development for businesses</h2>
                <p>
                    We deal with development of various custom software. From
                    design to implementation the process is carried out with the
                    client. Our software are built on various cloud services,
                    and in addition to web applications, our team also develops
                    mobile apps. BRAHMAN is aiming to use pioneering
                    technologies such as blockchain based products, big data and
                    artificial intelligence applications.
                </p>
            </div>

            <div class="image">
                <img
                    src="assets/img/section-title/section-title1.jpg"
                    alt="image"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-plugin"></i>
                    </div>
                    <h3>Product Engineering</h3>
                    <p>
                        We help you design your application based on many years
                        of industry experience, and also take care of the entire
                        product life cycle from designing, developing, testing
                        and supporting the software.
                    </p>
                    <!-- <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-computer-graphic"></i>
                    </div>
                    <h3>UX/UI Design</h3>
                    <p>
                        Our designers create a wireframe and a design from the
                        dreamed up idea, create an interactive demo of the
                        product. The latest trends and the right user experience
                        are very important to us.
                    </p>
                    <!-- <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-code"></i>
                    </div>
                    <h3>Custom software</h3>
                    <p>
                        We digitize your current work processes and transfer
                        them to the online space. Our experts help you to work
                        more efficiently and enjoyably within the company with
                        the latest tech stack based on cloud platforms.
                    </p>
                    <!-- <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3>Consulting for Startups</h3>
                    <p>
                        Knowing what an MVP is, what investors really want and
                        what frequent pivoting is, we can cooperate with startup
                        founders to transform their dreams into a product.
                    </p>
                    <!-- <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-application"></i>
                    </div>
                    <h3>Mobile Applications</h3>
                    <p>
                        If the application needs to be available on mobile, we
                        will help you find out whether a responsive website or
                        possibly a mobile application is the solution and we
                        develop it for your business.
                    </p>
                    <!-- <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-cyber-security"></i>
                    </div>
                    <h3>Pioneering Technologies</h3>
                    <p>
                        Whether it’s decentralized networks, blockchain,
                        artificial intelligence, deep learning, big data
                        analysis or the latest AR / VR technologies, contact us
                        and shape the future together.
                    </p>
                    <!-- <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a> -->
                </div>
            </div>
        </div>
    </div>

    <!--     <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div> -->
</section>
<!-- End Services Area -->

<!-- Start About Area -->
<section class="about-area" #aboutUs>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image" />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>
                        Technological innovation has always been our mission
                    </h2>
                    <p>
                        The strength of our team lies in our variety of
                        competences, with members from all relevant fields. The
                        founders have launched several successful startups,
                        gained experience in the private and public sector with
                        various finance and technology projects, achieved
                        outstanding professional results in several
                        international professional competitions.
                    </p>
                    <!-- <a routerLink="/about" class="btn btn-primary">View More</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-110">
    <div class="container">
        <!-- <div class="section-title">
            <div class="content">
                <span>Why choose us</span>
                <h2>We Different From Others Should Choose Us</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis suspendisse ultrices gravida. Risus commodo
                    viverra maecenas accumsan lacus vel facilisis.
                </p>
            </div>

            <div class="image">
                <img
                    src="assets/img/section-title/section-title2.jpg"
                    alt="image"
                />
            </div>
        </div> -->

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-choose-box">
                    <img
                        src="assets/img/why-choose-us/why-choose-us1.jpg"
                        alt="image"
                    />

                    <div class="title">
                        <h3>Industry Expertise</h3>
                    </div>

                    <div class="content">
                        <div class="inner-content">
                            <h3>Industry Expertise</h3>
                            <p>
                                We bringing years of experience working across
                                public and private sector. Our diverse
                                expertise gives us a unique perspective. We
                                discover new opportunities to maximize
                                efficiency in your workflows.
                            </p>
                            <!-- <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-choose-box">
                    <img
                        src="assets/img/why-choose-us/why-choose-us2.jpg"
                        alt="image"
                    />

                    <div class="title">
                        <h3>Flexibility & Availability</h3>
                    </div>

                    <div class="content">
                        <div class="inner-content">
                            <h3>Flexibility & Availability</h3>
                            <p>
                                Flexibility is a key factor, but never at the
                                expense of quality. Every project is unique, so
                                we never pre-define processes, and respond
                                quickly to client feedback.
                            </p>
                            <!-- <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
            >
                <div class="single-choose-box">
                    <img
                        src="assets/img/why-choose-us/why-choose-us3.jpg"
                        alt="image"
                    />

                    <div class="title">
                        <h3>Latest Technologies</h3>
                    </div>

                    <div class="content">
                        <div class="inner-content">
                            <h3>Latest Technologies</h3>
                            <p>
                                We help you understand how to use and implement
                                the latest technologies in your product, such as
                                AR/VR, deep learning, or Blockchain.
                            </p>
                            <!-- <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--     <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape/shape13.svg" alt="image"></div> -->
</section>
<!-- End Why Choose Us Area -->

<!-- Start FunFacts Area -->
<!-- <section class="funfacts-area ptb-110 bg-f2feee">
    <div class="container">
        <div class="row">
            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="60"></div>
                    <span>Design</span>
                    <h3>Codes</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="75"></div>
                    <span>Our happy</span>
                    <h3>Clients</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="85"></div>
                    <span>Projects</span>
                    <h3>Design</h3>
                </div>
            </div>

            <div
                class="
                    col-6 col-lg-3 col-md-3 col-sm-4
                    offset-lg-0 offset-md-0 offset-sm-4
                "
            >
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="95"></div>
                    <span>Completed</span>
                    <h3>Projects</h3>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End FunFacts Area -->

<!-- Start CTA Area -->
<section class="cta-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="cta-content">
            <span>Adapt technologically</span>
            <h2>Maximize efficiency</h2>
            <a routerLink="/contact" class="btn btn-primary">Contact Us</a>
        </div>
    </div>
</section>
<!-- End CTA Area -->

<!-- Start Projects Area -->
<!-- <section class="projects-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Recent projects</span>
                <h2>Proud Projects That Make Us Stand Out</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title3.png" alt="image">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="projects-slides owl-carousel owl-theme">
            <div class="col-lg-12 col-md-12">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects1.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Design/Idea</span>
                        <h3><a routerLink="/project-details">Flutter Development</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
                        <a routerLink="/project-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects2.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Design/Development</span>
                        <h3><a routerLink="/project-details">Application Development</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
                        <a routerLink="/project-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects3.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Design</span>
                        <h3><a routerLink="/project-details">Web Development</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
                        <a routerLink="/project-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects4.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Idea</span>
                        <h3><a routerLink="/project-details">Big Data Analysis</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
                        <a routerLink="/project-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects5.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Guard</span>
                        <h3><a routerLink="/project-details">Cyber Security</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
                        <a routerLink="/project-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section> -->
<!-- End Projects Area -->

<!-- Start Partner Area -->
<!-- <div class="partner-area bg-f2feee">
    <div class="container">
        <div class="partner-list">
            <div class="partner-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner1.png" alt="image"
                /></a>
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner1.png" alt="image"
                /></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner2.png" alt="image"
                /></a>
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner2.png" alt="image"
                /></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner1.png" alt="image"
                /></a>
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner1.png" alt="image"
                /></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner3.png" alt="image"
                /></a>
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner3.png" alt="image"
                /></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner4.png" alt="image"
                /></a>
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner4.png" alt="image"
                /></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner5.png" alt="image"
                /></a>
                <a href="#" target="_blank"
                    ><img src="assets/img/partner/partner5.png" alt="image"
                /></a>
            </div>
        </div>
    </div>
</div> -->
<!-- End Partner Area -->

<!-- Start Team Area -->
<!-- <section class="team-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Our Experts</span>
                <h2>Meet Our Leadership Preparing For Your Success</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title4.png" alt="image">
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>James Anderson</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Sarah Taylor</h3>
                        <span>Marketing Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Joe Root</h3>
                        <span>Lead Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="team-image">
                        <img src="assets/img/team/team4.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin-letters"></i></a></li>
                        </ul>
                    </div>

                    <div class="team-content">
                        <h3>Daisy Lucy</h3>
                        <span>Lead Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section> -->
<!-- End Team Area -->

<!-- Start Testimonials Area -->
<!-- <section class="testimonials-area">
    <div class="container-fluid p-0">
        <div class="testimonials-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="testimonials-inner-content">
                <div class="testimonials-slides owl-carousel owl-theme">
                    <div class="single-testimonials-item">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p>

                        <div class="client-info">
                            <img src="assets/img/author1.jpg" alt="image">
                            <h3>John Doe</h3>
                            <span>Marketing</span>
                        </div>
                    </div>

                    <div class="single-testimonials-item">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p>

                        <div class="client-info">
                            <img src="assets/img/author2.jpg" alt="image">
                            <h3>Steven Smith</h3>
                            <span>Marketing</span>
                        </div>
                    </div>

                    <div class="single-testimonials-item">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p>

                        <div class="client-info">
                            <img src="assets/img/author3.jpg" alt="image">
                            <h3>Sarah Taylor</h3>
                            <span>Designer</span>
                        </div>
                    </div>
                </div>

                <div class="circle-map"><img src="assets/img/circle-map3.png" alt="image"></div>
            </div>

            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
        </div>
    </div>
</section> -->
<!-- End Testimonials Area -->

<!-- Start Blog Area -->
<!-- <section class="blog-area extra-pb ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Latest News</span>
                <h2>Our Latest Insights Are On Top All Times</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title5.png" alt="image">
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post sticky">
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 16, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">10 Building Mobile Apps With Ionic And React</a></h3>
                    </div>
                </div>

                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 15, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 18, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">I Used The Web For A Day On A 50 MB Budget</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>

                <div class="single-blog-post link">
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 01, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">The hardest leadership advice to follow</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post quote">
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 08, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">How to share your company vision as a leader</a></h3>
                    </div>
                </div>

                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog3.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/blog">Admin</a></li>
                                <li>August 15, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Here are the 5 most telling signs of micromanagement</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section> -->
<!-- End Blog Area -->
