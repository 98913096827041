<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="{{location == '/' ? 'crimo-nav' : 'crimo-nav navbar-style-two'}}">
        <div class="{{location == '/' ? 'container-fluid' : 'container'}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/small_transparent_logo.png" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" class="nav-link">Home</a>
                        </li>

                        <!-- <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                Pages <i class="fas fa-chevron-down"></i>
                            </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/about" routerLinkActive="active" class="nav-link">About Us</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/team" routerLinkActive="active" class="nav-link">Team</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/not-found" routerLinkActive="active" class="nav-link">404 Error</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/coming-soon" routerLinkActive="active" class="nav-link">Coming Soon</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                Projects <i class="fas fa-chevron-down"></i>
                            </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/projects" routerLinkActive="active" class="nav-link">Projects</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/project-details" routerLinkActive="active" class="nav-link">Projects Details</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                Services <i class="fas fa-chevron-down"></i>
                            </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/services" routerLinkActive="active" class="nav-link">Services</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/service-details" routerLinkActive="active" class="nav-link">Services Details</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                Blog <i class="fas fa-chevron-down"></i>
                            </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/blog-details" routerLinkActive="active" class="nav-link">Blog Details</a>
                                </li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a routerLink="/services" routerLinkActive="active" class="nav-link">Szolgáltatások</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/about" routerLinkActive="active" class="nav-link">Rólunk</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/projects" routerLinkActive="active" class="nav-link">Projektek</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Kapcsolat</a>
                        </li> -->
                        <li class="nav-item">
                            <a href="/blog" class="nav-link">Blog</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li>
                    </ul>

                    <!-- <div class="others-options">
                        <div class="option-item">
                            <i class="search-btn flaticon-search"></i>
                            <i class="close-btn fas fa-times"></i>

                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">
                                        <button class="search-button" type="submit">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
