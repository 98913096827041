<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <!--  <div *ngIf="location === '/'" class="subscribe-area">
            <h3>Subscribe To Our Newsletter</h3>

            <form class="newsletter-form">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <input type="text" class="form-control" placeholder="Your Name" name="name" id="name">
                    </div>

                    <div class="col-lg-5 col-md-6 col-sm-6">
                        <input type="email" class="form-control" placeholder="Your Email" name="EMAIL" required autocomplete="off">
                    </div>

                    <div class="col-lg-3 col-md-12 col-sm-12">
                        <button type="submit">Subscribe Now <i class="flaticon-paper-plane"></i></button>
                    </div>
                </div>
            </form>
        </div>
 -->
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"
                            ><img
                                src="assets/img/small_transparent_logo_white.png"
                                alt="image"
                        /></a>
                        <p>
                            We develop custom softwares for businesses to
                            maximize their efficiency. Through deep industry insights, we provide custom software development with IT consulting.
                        </p>
                    </div>

                    <ul class="social">
                        <li>
                            <a href="#" target="_blank"
                                ><i class="flaticon-facebook-letter-logo"></i
                            ></a>
                        </li>
                        <!-- <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-youtube"></i></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-1 col-md-6 col-sm-6"></div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Our services</h3>

                    <ul class="footer-services-list">
                        <li>Product Engineering</li>
                        <li>UX/UI Design</li>
                        <li>Custom Softwares</li>
                        <li>Consulting for Startups</li>
                        <li>Mobile Applications</li>
                        <li>Pioneering Technologies</li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="quick-links-list">
                        <li><a routerLink="/">About Us</a></li>
                        <li><a routerLink="/">Blog</a></li>
                        <li><a routerLink="/">Contact</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Applications</a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact</h3>

                    <ul class="footer-contact-list">
                        <li><span>Address: </span> Budapest, Hungary</li>
                        <li>
                            <span>Email: </span>
                            <a href="mailto:bence.toth@brahmantechnologies.com">bence.toth@brahmantechnologies.com</a>
                        </li>
                        <li>
                            <span>Phone: </span>
                            <a href="tel:+36309615767">+30 30 961 5767</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>
                        &copy; Copyright {{ currentYear }}
                        <a routerLink="/">Brahman Technologies</a>. All rights reserved
                    </p>
                </div>

                <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>

    <div class="circle-map">
        <img src="assets/img/circle-map.png" alt="image" />
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top">
    <i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i>
</div>
